var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',[_vm._l((_vm.features),function(feature,i){return [(
          i === 0 ||
          i === 1 ||
          i === 4 ||
          i === 5 ||
          i === 8 ||
          i === 9 ||
          i === 12 ||
          i === 13 ||
          i === 16 ||
          i === 17 ||
          i === 20 ||
          i === 21
        )?_c('v-col',{key:i,staticClass:"my-0 py-0",attrs:{"id":"color","cols":"12","sm":"6"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":"","customText":"true"}},'base-avatar-card',feature,false))],1):_c('v-col',{key:i,staticClass:"ma-0 pa-0",attrs:{"cols":"12","sm":"6"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":"","customText":"true"}},'base-avatar-card',feature,false))],1)]})],2):_c('v-row',[_vm._l((_vm.featuresMini),function(feature,j){return [(
          j === 0 ||
          j === 2 ||
          j === 4 ||
          j === 6 ||
          j === 8 ||
          j === 10 ||
          j === 12
        )?_c('v-col',{key:j,staticClass:"my-0 py-0",attrs:{"id":"color","cols":"12","sm":"12"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":"","customText":"true"}},'base-avatar-card',feature,false))],1):_c('v-col',{key:j,staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"12"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":"","customText":"true"}},'base-avatar-card',feature,false))],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }