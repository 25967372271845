<template>
  <v-container fluid>
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <template v-for="(feature, i) in features">
        <v-col
          v-if="
            i === 0 ||
            i === 1 ||
            i === 4 ||
            i === 5 ||
            i === 8 ||
            i === 9 ||
            i === 12 ||
            i === 13 ||
            i === 16 ||
            i === 17 ||
            i === 20 ||
            i === 21
          "
          id="color"
          class="my-0 py-0"
          :key="i"
          cols="12"
          sm="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
        <v-col v-else class="ma-0 pa-0" :key="i" cols="12" sm="6">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
      </template>
    </v-row>
    <v-row v-else>
      <template v-for="(feature, j) in featuresMini">
        <v-col
          v-if="
            j === 0 ||
            j === 2 ||
            j === 4 ||
            j === 6 ||
            j === 8 ||
            j === 10 ||
            j === 12
          "
          id="color"
          class="my-0 py-0"
          :key="j"
          cols="12"
          sm="12"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
        <v-col v-else class="my-0 py-0" :key="j" cols="12" sm="12">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AlternatingDealflow",
  computed: {
    featuresMini() {
      var tempArray = this.features;
      var indexes = [1, 2, 5, 6, 9, 10, 13, 14, 17, 18];
      tempArray = tempArray.filter(function (value, index) {
        return indexes.indexOf(index) == -1;
      });
      return tempArray;
    },
  },

  data: () => ({
    features: [
      {
        title: "Automated PDF scraping for spreading financials",
        text: "DealFlow offers tools for automated parsing of financial data from PDF documents, tools to spread financials, and save a lot of time & effort for Analyst.",
        link: "about",
      },
      {
        title: "",
        text: "",
        image: "pdf-download",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Private credit data",
        text: "DealFlow provides tools to capture and manage private credit data, customizable credit agreement templates, and integration with third-party credit data providers",
      },
      {
        title: "Document management",
        text: "DealFlow offers document management tools, including secure document storage and management, version control and audit trails, and document sharing and collaboration tools.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Workflow builder",
        text: "DealFlow provides a workflow builder that allows users to create and customize workflows based on their specific deal management processes. Workflows can be created for tasks such as due diligence, deal sourcing, and post-close integration, among others.",
      },
      {
        title: "Modular Screen Utilities",
        text: "DealFlow offers customizable screens for specific use cases, a drag-and-drop screen builder, and integration with existing workflows",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "PowerBI and Microsoft plugins",
        text: "DealFlow provides integration with PowerBI for data visualization and Microsoft Outlook and Excel plugins for seamless data integration.",
      },
      {
        title: "User permissions and access controls",
        text: "DealFlow provides granular user permissions and access controls to allow administrators to control what each user can access and modify within the platform. This helps ensure that sensitive deal information is only accessible to authorized users.",
        link: "about",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Audit trail",
        text: "DealFlow maintains an audit trail of all user activity within the platform, including logins, data access, and changes made to data. This helps firms monitor and identify any unauthorized access or suspicious activity.",
      },
      {
        title: "Workflow templates",
        text: "DealFlow provides pre-configured workflow templates for common deal processes, such as due diligence and post-close integration and these templates are customizable.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Integration with Data Providers",
        text: "Seamlessly integrate with data providers like Bloomberg, Reuters, Markit, etc, DealFlow offers integration with leading financial data providers, tools to import and integrate external data, and automated data mapping and normalization tools.",
      },
      {
        title: "Automated workflows",
        text: "DealFlow also provides automated workflows that can be triggered by specific events, such as a deal reaching a certain stage or a document being uploaded to the platform. This helps streamline the deal management process and ensures that tasks are completed in a timely manner.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Deal Sourcing & Pipeline management",
        text: "DealFlow's deal sourcing and pipeline management tools help manage deal sourcing and pipeline management, with contact management and email integration, as well as customizable deal pipeline stages.",
      },
    ],
  }),
};
</script>
<style scoped>
#color {
  background-color: #fefefe;
  font-size: 1.9rem;
}

.v-col {
  font-size: 50px;
}
</style>
